.sidebyside {
    margin: 0 15px;
}

.sidebyside td {
    vertical-align: top;
    width: 50%;
    padding: 0 7px;
}

.sidebyside td p:first-of-type {
    margin-top: 0;
}