.textblock {
    position: relative;
    z-index: 0;
    margin: 20px 0;
    width: 37em;
    user-select: contain;
}

.even {
    position: relative;
    border: 1px solid #888;
    padding: 2em 4em 4em 5em;
    min-height: 670px;
    box-shadow: -2px 2px 2px rgba(34,34,34,50%);
}

.odd {
    position: relative;
    border: 1px solid #888;
    padding: 2em 5em 4em 4em;
    min-height: 670px;
    box-shadow: 2px 2px 2px rgba(34,34,34,50%);
}

.skeuomorph .even {
    box-shadow: none;
    background-color: #f1e9d2;
    background-image: linear-gradient( to left, 
        rgba(100,100,100,0.7), 
        rgba(100,100,100,0.1), 
        transparent 6% );
}

.skeuomorph .odd {
    box-shadow: none;
    background-color: #f1e9d2;
    background-image: linear-gradient( to right, 
        rgba(100,100,100,0.7), 
        rgba(100,100,100,0.1), 
        transparent 6% );
}

.skeuomorph .even:before, .skeuomorph .odd:before {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: 12px;
    left: 12px;
    width: 70%;
    height: 98%;
    box-shadow: -10px 15px 10px rgba(0, 0, 0, 0.5);
    transform: rotate(-1deg) skew(-1deg) perspective(200px) rotateY(1deg);}

.skeuomorph .odd:before {
    right: 12px;
    left: auto;
    box-shadow: 10px 15px 10px rgba(0, 0, 0, 0.5);
    transform: rotate(1deg) skew(1deg) perspective(200px) rotateY(-1deg);
}

.header {
    margin: 0.5em auto;
    text-align: center;
    font-weight: bold;
}

.book {
    margin: 1em auto;
    text-align: center;
    font-weight: bold;
}

.init, .chunk {
    position: relative;
    text-align: justify;
}

.bekkerPage, .bekkerLine {
    position: absolute;
    font-size: 0.75em;
    white-space: nowrap;
}

.chapter {
    position: absolute;
    font-size: 1.5em;
    font-weight: bold;
}

.even .bekkerPage,
.even .bekkerLine {
    top: 0;
    right: calc(100% + 1em);
    margin-top: 0.5em;
    width: fit-content;
}

.odd .bekkerPage,
.odd .bekkerLine {
    bottom: 0;
    left: calc(100% + 1em);
}

.even .chapter {
    top: 0;
    left: calc(100% + 0.5em);
}

.odd .chapter {
    top: 0;
    right: calc(100% + 0.5em);
}

.pageNum {
    position: absolute;
    bottom: 3ex;
    width: calc(100% - 9em);
    text-align: center;
}

.margNote {
    user-select: none;
}

.allowMargSel .margNote {
    user-select: inherit;
}
