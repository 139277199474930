.textfield {
    margin-top: 0;
    width: 3em;
}

.controls {
    user-select: none;
}

.controls label {
    display: block;
    margin: 5px 0;
    cursor: pointer;
}

.display-line {
    display: flex;
    position: relative;
    align-items: center;
}

.left-arr, .right-arr {
    display: inline-block;
    position: relative;
    min-width: 55px;
    height: 670px;
    cursor: pointer;
}

.left-arr::before, .right-arr:before {
    content: '';
    position: absolute;
    top: calc(50% - 25px);
    width: 0;
    height: 0;
    border-top: 35px solid transparent;
    border-bottom: 35px solid transparent;
}

.left-arr::before {
    right: 20px;
    border-right: 25px solid #ccc;
}

.right-arr:before {
    left: 20px;
	border-left: 25px solid #ccc;
}

.left-arr:hover::before {
    border-right: 25px solid #aaa;
}

.right-arr:hover::before {
	border-left: 25px solid #aaa;
}

.left-arr.disabled::before,
.right-arr.disabled::before {
        border-right-color: #eee;
        border-left-color: #eee;
        pointer-events: none;
}
