.custom-context {
    display: inline-block;
    position: relative;
    z-index: 999;
    margin: 5px;
    border: solid 1px #ccc;
    min-width: 250px;
    font-size: 12px;
    font-family: sans-serif;
    cursor: pointer;
    background: #eee;
    color: #333;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    user-select: none;
}

.custom-context-item {
    padding: 5px 10px 5px 25px;
}
.custom-context-item:hover {
    background-color: #ddd;
}

.custom-context-item.bottomline {
    border-bottom: solid 1px #ccc;
}

.custom-context-item.inactive {
    color: #888;
}

.context-menu-bg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
