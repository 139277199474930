a {
 text-decoration: none; 
}

App {
    font-size: 20px;
}

.greek {
    font-family: Aristarcoj, "Palatino Linotype", serif;  
}

.subsection {
    font-size: 1.1em;
    font-weight: bold;
    font-variant: all-small-caps;
}

.instructions {
    font-style: italic;
}

.forFullscreenParent {
  position: relative;
}

.forFullscreenParent > .fullscreenButton {
  position: absolute;
  top: 16px;
  right: 16px;
}

.fullscreenButton {
  display: inline-block;
  border: 1px solid #222;
  border-radius: 4px;
  padding: 3px;
  width: 25px;
  height: 25px;
  background-color: rgba(255, 255, 255, 0.3);
  cursor: pointer;
}

.fullscreenButton:hover {
  background-color: Highlight;
}

/* for use in fullscreen mode */
.scrollable {
  overflow: auto;
}


