a {
    color: hsl(231, 48%, 40%);
}
a:visited {
    color: hsl(231, 48%, 35%);
}
a:hover {
    color: hsl(231, 48%, 50%);
    text-decoration: underline;
}
a:active {
    color: hsl(231, 48%, 60%);
}

h2 {
    font-size: 1em;
    font-weight: bold;
}

hr {
    margin-left: 0;
    width: 33%;
}

details {
  padding: 0 12px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
  0px 1px 1px 0px rgb(0 0 0 / 14%),
  0px 1px 3px 0px rgb(0 0 0 / 12%);
}

details[open] {
  margin: 16px 0;
  padding: 0 12px 12px;
}

details[open]:first-child {
  margin-top: 0;
}

summary {
  padding: 12px 0;
  color: #283371;
  font-size: 1.25rem;
  font-weight: 700;
}

.notes {
    font-size: 0.7em;
}

.bib {
    padding-left: 3em;
    text-indent: -3em;
}

/*
Closed:
<svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path></svg>
Open:
<svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path></svg>
*/